.root {
    width: 70%;
  }
  
  .divstyle {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 16px;
  }
  
  .divstyleNext {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 16px;
    width: 92%;
  }
  
  .fullwidth {
    margin: 16px 40px;
  }
  
  @media only screen and (max-width: 1023px) {
    /* For tablets: */
    .root {
      width: 100%;
    }
    .divstyle {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-around;
      padding: 16px;
    }
    .fullwidth {
      margin: 16px;
    }
  }
  
  @media only screen and (max-width: 520px) {
    /* For mobiles: */
    .fullwidth {
      margin: 0px;
    }
  }
  
