html {
  height: 100%;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #FCFCFC;
  position: relative;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
/* views css */
.Views {
  display: flex;
}
.allViewContainers {
  display: flex;
  margin: 16px 40px;
  width: 100%;
}
.allViewContainersNew {
  display: flex;
  flex-direction: column;
  margin: 16px 40px;
  width: 100%;
}
.sroot {
  padding-left: 15px;
}

/* Navs css */
.navigationColumn {
  display: flex;
  height: 100%;
  min-width: 100px;
}

.bottomNavigation {
  display: none;
}
/* searchContainersCss */
.SearchConatiner {
  width: 100%;
  /* position: fixed; */
  padding: 10px;
  overflow-x: auto;
}

.CreateContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: 40px;
}
/* ecr css */
.ecrroot {
  display: flex;
  flex-direction: column;
  width: calc(100% - 100px);
  margin: 16px 40px;
}

.linker {
  text-decoration: none;
  display: flex;
  align-self: flex-end;
  justify-content: flex-end;
}

.ViewECR {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.card {
  width: 350px;
  margin: 16px 0px;
}

.Radiogroup {
  display: flex;
  flex-wrap: wrap;
  width: 600px;
}

.titleTextAlinement {
  padding-left: 24px;
}

.Cardrows {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.NewRoot {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}

.senderLoader {
  width: 90vw;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (max-width: 1439px) {
  /* For tablets: */
  .CreateContainer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-left: 0px;
  }
  .card {
    width: 350px;
    margin: 16px 0px;
  }
  .ecrroot {
    display: flex;
    flex-direction: column;
    width: calc(100% - 100px);
    margin: 16px 0px;
  }
  .NewRoot {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
  }
}

@media only screen and (max-width: 520px) {
  /* For mobiles: */
  .noMargin {
    margin: 0;
  }

  .sroot {
    padding-left: 0px;
  }

  .Views {
    display: flex;
    flex-direction: column;
    margin-bottom: 80px;
  }

  .allViewContainers {
    display: flex;
    width: 100%;
    margin: 0;
  }

  .allViewContainersNew {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 0;
  }

  .navigationColumn {
    display: none;
  }

  .bottomNavigation {
    display: block;
  }

  .ecrroot {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 16px 0px;
  }

  .ViewECR {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .card {
    width: 100%;
    margin: 16px 0px;
  }

  .Radiogroup {
    display: flex;
    flex-wrap: wrap;
    width: 300px;
  }

  .Cardrows {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
  }

  .CreateContainer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-left: 0px;
    width: 100%;
  }
  .NewRoot {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .senderLoader {
    width: 100%;
  }
}
