.Containss {
  width: 60%;
}

.divstyle {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 16px;
}

.divstyleNext {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 16px;
  /* width: 92%; */
}

.fullwidth {
  margin: 16px 40px;
}

.ViewURS {
  display: flex;
  justify-content: space-between;
}

.buttonssss {
  color: white !important;
  margin-top: 8px !important;
  margin-bottom: 8px !important;
}


@media only screen and (max-width: 1023px) {
  /* For tablets: */
  .Containss {
    width: 100%;
  }
  .divstyle {
    display: flex;
    flex-wrap: wrap;
    /* justify-content: space-around; */
    padding: 16px;
  }
  .fullwidth {
    margin: 16px;
  }
  .ViewURS {
    display: flex;
    flex-wrap: wrap-reverse;
  }
}

@media only screen and (max-width: 520px) {
  /* For mobiles: */
  .fullwidth {
    margin: 0px;
  }
  .ViewURS {
    display: flex;
    flex-wrap: wrap-reverse;
  }
  .divstyleNext {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 16px;
    width: 92%;
  }
}
