.CreateContainer {
  display: flex;
  justify-content: flex-end;
  flex-wrap: wrap;
  margin-top: 24px;
  margin-left: 40px;
}

@media only screen and (max-width: 1439px) {
  /* For tablets: */
  .CreateContainer {
    display: flex;
    justify-content: flex-end;
    flex-wrap: wrap;
    margin-top: 24px;
    margin-left: 0px;
  }
}
